.connection-message-settings-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .connection-message-settings-page-header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 24px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 16px Inter, 'Arial', sans-serif;
        margin: 0 0 20px 0;
      }
    }

    .page-content {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      min-height: 50vh;

      .connection-message-input {
        position: relative;

        .text-area-input {
          width: 100%;
          padding: 10px 60px 10px 8px;
        }

        .connection-message-length {
          position: absolute;
          bottom: 10px;
          right: -60px;
          font: 500 13px Inter, 'Arial', sans-serif;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .footer-buttons {
        display: flex;
        column-gap: 25px;
      }
    }
  }
}