.sidebar {
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.09);
  border: 1px solid #E2E8F0;
  background: #FFFFFF;
  z-index: 5;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

