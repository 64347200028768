.tag {
  border-radius: 6px;
  padding: 6px 15px;
  text-align: center;
  font: 400 15px Inter, sans-serif, Arial;

  .tag-content {
    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: center;
  }
}