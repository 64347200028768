.linked-in-settings-content {
  display: flex;
  gap: 10px;
  flex-flow: column;

  .section-container {
    margin-top: 10px;

    .input-title {
      font: 600 16px Inter, 'Arial', sans-serif;
      margin: 0;
    }

    .input-subtitle {
      font: 400 16px Inter, 'Arial', sans-serif;
      color: #64748B;
      margin: 10px 0 20px 0;
    }

    .linked-in-settings-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 15px;
    }
  }
}