.button.primary-button {
  background: #5200FF;
  color: #FFFFFF;
  border: 1px solid #5200FF;

  &:disabled {
    background: #7A7A7A;
    border: 1px solid #7A7A7A;

    &:hover {
      background: #7A7A7A;
      border: 1px solid #7A7A7A;
      cursor: auto;
    }
  }

  &:hover {
    background: #4600DB;
    border: 1px solid #4600DB;
  }

  &.loading {
    &:hover {
      background: #5200FF;
      border: 1px solid #5200FF;
    }
  }
}