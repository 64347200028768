.credit-details {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 4px 4px rgba(174, 174, 174, 0.25);
  padding: 25px 15px;
  border-radius: 6px;
  width: 500px;
  height: 121.5px;

  &.loading {
    .credit-details-summary-container {
      margin: 45px 0;
      display: flex;
      justify-content: center;
    }
  }

  .credit-details-title {
    margin: 0;
    font: 600 15px Inter, Arial sans-serif;
  }

  .credit-details-summary {
    margin: 20px 0 25px 0;
    font: 400 15px Inter, Arial sans-serif;

    b {
      font-weight: 600;
    }
  }

  .progress-bar-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .progress-bar {
      width: 100%;
    }

    .counter {
      font: 500 14px Inter, Arial sans-serif;
    }
  }
}