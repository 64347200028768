.nurture-flow-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 28px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 18px Inter, 'Arial', sans-serif;
        margin: 0 0 30px 0;
      }
    }

    .page-content {
      display: flex;
      flex-flow: column;
      gap: 10px;

      .page-section {
        margin-bottom: 10px;

        .follow-up-messages {
          margin: 20px 0;
          width: calc(100vw - 380px);
          justify-content: center;
          display: flex;
          flex-flow: column;
          align-items: center;
          gap: 20px;
        }

        .section-header {
          display: flex;
          align-items: center;
          gap: 25px;
          margin: 10px 0 15px 0;

          .section-title {
            margin: 0;
            font: 600 18px Inter, 'Arial', sans-serif;
          }

          .off-text {
            color: #64748B;
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }
    }
  }
}