.dashboard-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 10px 20px 30px;
  width: calc(100vw - 360px);

  .page {
    display: flex;
    flex-flow: column;

    .dashboard {
      .dashboard-header {
        .title { 
          margin: 0 0 15px 0;
          font: 700 28px Inter, 'Arial', sans-serif;
        }
      
        .subtitle {
          font: 400 18px Inter, 'Arial', sans-serif;
          margin: 0 0 30px 0;
        }
      }
    }
  }
}