.verification-digit {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 32px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  position: relative;
  margin: 0 1rem;
  font-family: Inter, sans-serif, Arial;
  border-radius: 3px;

  @media (max-width: 480px) {
    width: 24px;
    height: 32px;
    margin: 0 0.5rem;
    font-size: 24px;
  }

  .shadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 2px solid #9775E3;
  }
}