.about-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .about-page-header {

      // TODO: Perhaps we want to generalize this code - it's copied in a lot of places
      .title { 
        margin: 0 0 15px 0;
        font: 600 24px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 16px Inter, 'Arial', sans-serif;
        margin: 0 0 30px 0;
      }
    }

    .page-content {
      display: flex;
      gap: 30px;
      flex-flow: column;
      
      .preview-container {
        .preview-iframe {
          min-width: 600px;
          min-height: 320px;
        }
      }

      .call-to-action {
        display: flex;
        flex-flow: column;
        gap: 20px;

        .call-to-action-label {
          color: #0F172A;
          font: 400 16px Inter, 'Arial', sans-serif;
        }

        .call-to-action-setup-button {
          width: 100px;
        }
      }
    }
  }
}