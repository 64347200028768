.primary-input::placeholder {
  color: #94A2B8;
}

.primary-input {
  border: 1px solid #CBD5E1;
  background: #FFFFFF;
  padding: 10px 10px;
  font: 400 15px Inter, sans-serif, Arial;
  color: #121827;
  min-width: 200px;
  border-radius: 6px;
  outline-width: 2px;
  outline-color: #94A2B8;
  outline-offset: 4px;

  &:disabled {
    opacity: 0.8;
    color: #94A2B8;
  }
}