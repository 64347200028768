.quick-select-modal {
  position: absolute;
  z-index: 4;
  padding-top: 5px;
  width: 100%;

  .quick-select-container {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 10px 5px;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.09));
    border-radius: 6px;
    row-gap: 10px;

    .quick-select-option-button {
      border: none;
      background-color: #FFFFFF;
      cursor: pointer;
      text-align: left;
      width: 100%;
      padding: 4px 10px;
      font: 400 14px Inter, 'Arial', sans-serif;

      &:hover {
        font-weight: 600;
      }
    }
  }
}