ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

hr {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.dropdown {
  width: fit-content;
  z-index: 1;

  .button.dropdown-button {
    background: #FFFFFF;
    color: #0F172A;
    border: 1px solid #E2E8F0;
    min-width: 200px;
    width: 100%;
    
    .button-content {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .button-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &.open {
      .button-content {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .dropdown-options-container {
    position: relative;
    height: 0;

    .dropdown-options {
      position: absolute;
      width: calc(100% - 2px);
      background: #FFFFFF;
      filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.09));
      border: 1px solid #F1F5F9;
      display: flex;
      flex-flow: column;
      gap: 3px;
      top: 5px;
      padding: 5px 0;
      border-radius: 6px;

      .main-options {
        max-height: 200px;
        overflow: auto;
        display: flex;
        flex-flow: column;
        gap: 5px;
      }

      .divider {
        border-top: 1px solid #F1F5F9;
        margin-top: 3px;
        width: 100%;
      }

      .dropdown-option {
        .button.additional-options-button.dropdown-selection-option {
          font-weight: 600;

          &:hover {
            background: transparent;
          }
        }

        .button.dropdown-selection-option {
          padding: 8px;
          width: calc(100% - 10px);
          margin: 0 5px;
          background: transparent;
          color: #0F172A;

          .button-content {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .text {
              text-align: left;
            }

            .selected-icon {
              opacity: 0;
              margin-right: 8px;
              width: 18px;
              height: 18px;
            }
          }

          &.selected {
            background: #EAE8FA;
            font-weight: 600;
            
            .button-content {
              .selected-icon {
                opacity: 1;
              }
            }
          }

          &:hover {
            background: #EAE8FA;
          }
        }
      }
    }
  }
}
