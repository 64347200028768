.case-study-card {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-width: 260px;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  padding: 25px 20px;
  min-height: 280px;
  color: #0F172A;
  box-shadow: 0px 4px 4px rgba(174, 174, 174, 0.25);
  width: 260px;

  .case-study-label {
    background: #EAE8FA;
    color: #0F172A;
    padding: 8px 20px;
    font: 600 16px Inter, 'Arial', sans-serif;
    align-self: flex-start;
    border-radius: 5px;
  }

  .card-content {
    .case-study-card-header {
      display: flex;
      margin-top: 30px;
  
      .title {
        font: 600 16px Inter, 'Arial', sans-serif;
        color: #0F172A;
        text-align: left;
        max-width: 300px;
        margin: 10px 0;
      }
    }

    .card-description {
      min-height: 180px;

      .description-line {
        font: 300 14px Inter, 'Arial', sans-serif;
        color: #0F172A;
        border-bottom: 1px solid #E2E8F0;
        padding: 15px 0;
        margin: 0;
      }
    }
  }

  .learn-more-button {
    margin-top: 20px;
    width: 120px;
    padding: 10px;
    align-self: center;
  }
}
