.reactivate-clients-settings-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .reactivate-clients-settings-page-header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 24px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 16px Inter, 'Arial', sans-serif;
        margin: 0 0 20px 0;
      }
    }

    .page-content {
      display: flex;
      gap: 10px;
      flex-flow: column;
    }

    .create-request-button {
      width: 100px;
      margin-top: 10px;
    }
  }
}
