.pagination-bar {
  display: flex;
  align-items: center;
  gap: 8px;

  .button {
    &.pagination-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41.5px;
      height: 41.5px;
      padding: 0;
    }

    .button-content {
      display: flex;
      justify-content: center;
    }
  }

  .left-button-container {
    width: 127px;
    height: 41.5px;
  }

  .right-button-container {
    width: 127px;
    height: 41.5px;
  }

  .ellipsis-container {
    width: 41.5px;
    height: 41.5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ellipsis {
      width: 14px;
      height: 14px;
    }
  }
}