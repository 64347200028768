.search-container {
  position: relative;
  display: flex;
  align-items: center;

  .primary-input.search-input {
    background-color: #FFFFFF;
    box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    border: none;
    padding-left: 48px;
    width: 500px;
  }

  .search-input-icon {
    position: absolute;
    left: 16px;
  }
}
