.auth-linkedin-modal-content {
  display: flex;
  flex-direction: column;
  width: 500px;
  
  .title { 
    margin: 0 0 15px 0;
    font: 600 24px Inter, 'Arial', sans-serif;
  }

  .subtitle {
    font: 400 16px Inter, 'Arial', sans-serif;
    margin: 0 0 20px 0;
  }

  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    
    .input-label {
      width: 200px;
      font: 500 16px Inter, 'Arial', sans-serif;
    }

    .primary-input {
      min-width: 300px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    column-gap: 2rem;
  }
}