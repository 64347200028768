.commercial-outreach-manage-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .commercial-outreach-manage-page-header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 24px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 16px Inter, 'Arial', sans-serif;
        margin: 0 0 20px 0;
      }
      
      .table-buttons {
        display: flex;
        align-items: center;
        gap: 15px;
        
        .button {
          font: 400 16px Inter, 'Arial', sans-serif;
        }

        .show-completed-toggle {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .flatfile_iframe-wrapper iframe {
          height: 100% !important;
        }
      }

      .search-and-filter-bar {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 25px 0;
      }

      .linked-in-user-count {
        font: 600 14px Inter, Arial, sans-serif;
      }
    }

    .page-content {
      display: flex;
      gap: 10px;
      flex-flow: column;
      margin-top: 20px;
    }
  }
}