.table {
  border-collapse: separate;
  margin-right: 30px;
  border-spacing: 0;

  .table-header {
    background: #FFFFFF;

    tr {
      th {
        font: 600 15px Inter, 'Arial', sans-serif;
        border-top: 1px solid #E2E8F0;
        border-right: 1px solid #E2E8F0;
        padding: 10px 15px;
        text-align: left;
        white-space: nowrap;

        &:first-child {
          border-left: 1px solid #E2E8F0;
        }

        &.one-button-cell {
          .icon-button {
            border: none;
            margin: 0 auto;
            padding: 0;

            &:hover {
              background: transparent;
            }
          }
        }

        &.hidden {
          border-top: none;
          border-left: none;
        }

        &.sticky {
          position: sticky;
          left: 0;
          background: #FFFFFF;
          z-index: 1;
        }

        .cell-text {
          display: flex;
          justify-content: space-between;

          .cell-content {
            display: flex;
            align-items: center;
            gap: 7px;
          }

          .tooltip-container {
            z-index: 3;
            position: relative;
            top: 2px;
            
            .tooltip-content {
              top: -55px;
              font-size: 14px;
              left: 10px;
              max-width: 320px;
            }
          }
        }

        .clickable {
          cursor: pointer;
        }
      }
    }
  }

  .table-body {
    position: relative;
    z-index: 2;

    tr {
      &:last-child {
        td {
          border-bottom: 1px solid #E2E8F0;
        }
      }

      td {
        border-top: 1px solid #E2E8F0;
        border-right: 1px solid #E2E8F0;
        height: 40px;
        font: 400 14px Inter, 'Arial', sans-serif;

        &.one-button-cell {
          .icon-button {
            border: none;
            margin: 0 auto;
            padding: 0;

            &:hover {
              background: transparent;
            }
          }
        }

        &:first-child {
          border-left: 1px solid #E2E8F0;
        }

        &.counter-column {
          padding: 5px 5px;
          color: #94A3B8;
          font: 400 11px Inter, 'Arial', sans-serif;

          // Used to forcibly constrain the dimensions of the counter column
          max-width: 32px;
          min-width: 32px;
          text-align: center;
          width: 32px;
        }

        .button {
          padding: 8px 15px;
        }

        &.sticky {
          position: sticky;
          left: 0;
          background: #FFFFFF;
          z-index: 1;
        }

        .two-cell {
          width: 200px;
          padding: 0px 10px;
          display: flex;
          align-items: center;
          min-height: 19px;

          &.short-text {
            display: inline-block;
            padding: 5px 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
            &:hover {
              cursor: pointer;
            }
          }

          &.long-text {
            height: auto;
            white-space: wrap;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              cursor: pointer;
            }
          }

          &.dropdown {
            display: block;
            margin: 0 auto;
          }
        }

        .tag-cell {
          width: 74px;
          margin: 5px 10px;
        }

        .long-cell {
          padding: 0px 10px;
          display: flex;
          align-items: center;
          min-height: 19px;
        }

        .clickable {
          cursor: pointer;
        }

        .long-tag-cell {
          margin: 5px 10px;
          text-wrap: nowrap;
        }

        .button-cell {
          width: 94px;
          margin: 5px 10px;
        }

        .one-and-half-cell {
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0px 10px;
        }

        .one-cell {
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }

        .half-cell {
          width: 65px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 5px 10px;
        }

        .ellipsis-dropdown {
          .dropdown-options-container {
            .dropdown-options {
              left: -120px;
            }
          }
        }
      }
    }
  }
}
