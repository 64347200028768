.swap-auth-container {
  display: flex;

  .has-account-no-account-text {
    font-size: 12px;
    font-family: Inter, sans-serif, Arial;
    margin: 0;
  }

  .auth-switch-button {
    margin-left: 0.5rem;
    color: #5200FF;
    font-weight: 700;
    font-size: 12px;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    font-family: Inter, sans-serif, Arial;
  }
}