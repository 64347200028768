.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 40px 24px;
  box-sizing: border-box;
  width: 100%;

  text-align: center;

  .login-rex-logo {
    width: 60px;
  }

  .login-title {
    font-weight: 700;
    font-size: 24px;
    margin: 1rem 0;
    font-family: Inter, sans-serif, Arial;
  }

  .login-subtitle {
    font-size: 14px;
    margin: 0;
    font-family: Inter, sans-serif, Arial;
  }
}