.linked-in-connection-buttons {
  display: flex;
  align-items: center;
  column-gap: 15px;

  .linked-in-connected-button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }
}