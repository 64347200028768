.text-area-input::placeholder {
  color: #94A2B8;
}

.text-area-input {
  border: 1px solid #CBD5E1;
  background: #FFFFFF;
  padding: 10px 8px;
  font: 500 15px Krub, sans-serif, Arial;
  color: #121827;
  min-width: 200px;
  border-radius: 6px;
  outline-width: 2px;
  outline-color: #94A2B8;
  outline-offset: 4px;
  resize: none;

  &:disabled {
    opacity: 0.8;
    color: #94A2B8;
  }
}