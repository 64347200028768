.preview-message-container {
  width: 100%;
  
  .preview-message {
    border: 1px solid #E4E4E6;
    background: #FFFFFF;
    border-radius: 10px;
    
    .message-container {
      padding: 20px;
    }
  }
  .pagination-bar {
    justify-content: center;
    margin-top: 15px;
  }
}