.email-forwarding {
  display: flex;
  flex-flow: column;
  gap: 20px;

  .section-container {
    margin-top: 15px;

    .input-title {
      font: 600 16px Inter, 'Arial', sans-serif;
      margin: 0;
    }

    .input-subtitle {
      font: 400 16px Inter, 'Arial', sans-serif;
      color: #64748B;
      margin: 10px 0 20px 0;
    }

    .sub-input-title {
      font: 600 16px Inter, 'Arial', sans-serif;
      margin: 20px 0 10px;
    }

    .email-forwarding-list {
      display: flex;
      flex-flow: column;
      margin-top: 10px;

      .email-to-forward {
        display: flex;
        flex-flow: row;
        align-items: center;
        gap: 5px;
        font: 500 16px Krub, sans-serif, Arial;
        color: rgba(15, 23, 42, 0.5);

        .index {
          min-width: 40px;
        }

        .name {
          min-width: 360px;
        }

        .trash-icon-button {
          height: 40px;
        }
      }
    }

    .input-container {
      display: flex;
      align-items: center;
      
      .primary-button {
        margin-left: 2rem;
      }
      
      .input-label {
        width: 200px;
        font: 500 16px Inter, 'Arial', sans-serif;
      }

      .pac-target-input {
        border: 1px solid #CBD5E1;
        background: #FFFFFF;
        padding: 10px 8px;
        font: 500 16px Krub, sans-serif, Arial;
        color: #121827;
        min-width: 200px;
        border-radius: 6px;
        outline-width: 2px;
        outline-color: #94A2B8;
        outline-offset: 4px;
      
        &:disabled {
          opacity: 0.8;
          color: #94A2B8;
        }
      }

      input.pac-target-input::placeholder {
        color: #94A2B8;
      }

      .primary-input, .pac-target-input {
        min-width: 420px;
      }
    }
  }
}