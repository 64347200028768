.view-leads-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .dashboard {
      .dashboard-header {
        .title { 
          margin: 0 0 15px 0;
          font: 600 28px Inter, 'Arial', sans-serif;
        }
      
        .subtitle {
          font: 400 18px Inter, 'Arial', sans-serif;
          margin: 0 0 30px 0;
        }
      }

      .leads-metrics-container {
        display: flex;
        flex-flow: row;
        gap: 20px;
        margin-bottom: 30px;

        .dashboard-metrics {
          display: flex;
          flex-flow: column;
          gap: 12px;

          .metric-title {
            font: 600 16px Inter, 'Arial', sans-serif;
          }

          .dashboard-metric-list {
            display: flex;
            gap: 20px;

            // Make first element information tooltip start left to right

            .dashboard-metric-item:first-child, .dashboard-metric-item:nth-child(2) {
               .metric .metric-count .metric-label .tooltip-container .tooltip {
                .tooltip-content {
                  left: 0px;
                }
              }
            }
          }
        }
      }
    }


    .case-studies-page-header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 22px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 18px Inter, 'Arial', sans-serif;
        margin: 0 0 30px 0;
      }
    }

    .page-content {
      display: flex;
      gap: 30px;
    }
  }
}