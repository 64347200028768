.work-rex-page {
  display: flex;
  padding: 20px;

  // Used to prevent the page from scrolling horizontally overall
  width: calc(100% - 40px);
  overflow: hidden;

  .sidebar-buffer-container {
    margin-left: 280px;
  }
}