.accordion {
  .accordion-content {
    display: none;
  }

  &.open {
    .accordion-button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .button-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .accordion-content {
      display: flex;
      flex-flow: column;
    }
  }
}