.out-of-credits-modal {
  .modal-content {
    padding: 32px;

    .out-of-credits-modal-content {
      display: flex;
      flex-flow: column;

      .modal-header {
        margin-bottom: 20px;
        
        .modal-title {
          color: #0F172A;
          font: 600 24px Inter, 'Arial', sans-serif;
          margin: 0;
        }

        .modal-subtitle {
          margin: 15px 0 10px 0;
          color: #64748B;
          font: 400 16px Inter, 'Arial', sans-serif;
        }
      }
      
      .close-button-container {
        margin-top: 20px;
        align-self: flex-end;
      }
    }
  }
}