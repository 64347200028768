.generic-filter {
  display: flex;
  align-items: center;
  gap: 20px;

  .outline-button.filter-button {
    font-weight: 400;
  }

  .filter-count {
    font: 400 15px Inter, sans-serif, Arial;
  }
}