.button.ghost-button.cross-icon-button {
  padding: 0;
  height: 14px;
  width: 14px;

  img {
    height: 14px;
    width: 14px;
    position: relative;
    top: -2px;
  }


  &:hover {
    transform: scale(1.3);
    background: transparent;
  }
}