.recover-clients-setup-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .recover-clients-setup-page-header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 24px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 16px Inter, 'Arial', sans-serif;
        margin: 0 0 20px 0;
      }
    }

    .page-content {
      display: flex;
      gap: 10px;
      flex-flow: column;
      min-width: 500px;

      .section-container {
        margin-top: 10px;

        .input-title {
          font: 400 16px Inter, 'Arial', sans-serif;
          margin: 0 0 20px 0;
        }

        .places-list {
          display: flex;
          flex-flow: column;
          margin-top: 10px;

          .place {
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: 5px;
            font: 500 15px Krub, sans-serif, Arial;
            color: rgba(15, 23, 42, 0.5);

            .index {
              min-width: 40px;
            }

            .name {
              min-width: 360px;
            }

            .trash-icon-button {
              height: 40px;
            }
          }
        }

        .input-container {
          display: flex;
          flex-direction: column;
          
          .input-label {
            width: 200px;
            font: 500 16px Inter, 'Arial', sans-serif;
          }

          .pac-target-input {
            border: 1px solid #CBD5E1;
            background: #FFFFFF;
            padding: 10px 8px;
            font: 500 15px Krub, sans-serif, Arial;
            color: #121827;
            min-width: 200px;
            border-radius: 6px;
            outline-width: 2px;
            outline-color: #94A2B8;
            outline-offset: 4px;
          
            &:disabled {
              opacity: 0.8;
              color: #94A2B8;
            }
          }

          input.pac-target-input::placeholder {
            color: #94A2B8;
          }

          .primary-input, .pac-target-input {
            min-width: 420px;
          }
        }
      }
    }

    .create-request-button {
      width: 100px;
      margin-top: 10px;
    }
  }
}
