.button.nav-sub-item {
  background: transparent;
  border-radius: 0;
  padding: 8px 8px 8px 15px;
  margin: 0 24px;

  .button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      margin-left: 30px;
      color: #334155;
      font: 400 15px Inter, 'Arial', sans-serif;
    }

    .count {
      min-width: 18px;
      min-height: 18px;
      color: #334155;
    }
  }

  &.selected {
    background: #EAE8FA;

    .text {
      font: 600 15px Inter, 'Arial', sans-serif;
    }
  }
}