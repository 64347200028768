.button {
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 10px 20px;
  font: 400 15px Inter, sans-serif, Arial;

  &:hover {
    cursor: pointer;

    &:disabled, &[disabled=true] {
      cursor: auto;
    }
  }

  &.loading {
    &:hover {
      cursor: auto;
    }
  }
}