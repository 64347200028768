.confirmation-modal-content {
  width: 510px;
  display: flex;
  flex-flow: column;

  .title {
    font: 600 20px/24px 'Inter', Arial, sans-serif;
    color: #0F172A;
    margin: 0 0 10px 0;
  }

  .subtitle {
    font: 400 16px/18px 'Inter', Arial, sans-serif;
    color: #64748B;
    margin: 0 0 30px 0;
    line-height: 24px;
  }

  .buttons {
    align-self: flex-end;
    display: flex;
    gap: 20px;
  }
}
