.multi-select-row {
  .multi-select-row-grid {
    display: grid;
    grid-template-columns: 33% 33% 33% 12px;
    column-gap: 15px;
    width: 100%;

    .reactivate-client-settings-attribute-dropdown, .reactivate-client-settings-conditions-dropdown {
      width: 100%;
      position: relative;
      z-index: 2;
    
      &.dropdown-open {
        z-index: 3;
      }
    
      .dropdown-button {
        z-index: 1;
      }
    
      .dropdown-options-container {
        z-index: 3;
      }
    }
    
    .primary-input {
      box-sizing: border-box;
      min-width: unset;
    }

  }
}