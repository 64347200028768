.recover-clients-table-container {
  overflow: auto;
  width: calc(100vw - 360px);
  padding-bottom: 30px;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  
  .table-container {

    .table .table-body tr td .one-button-cell {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .button-cell {
        padding: 8px 10px;
        font-size: 14px;
      }
    }

    .table.recover-clients-table {
      .table-header {
        tr {
          th {
            .cell-text {
              display: flex;
              justify-content: space-between;
    
              .cell-content {
                display: flex;
                align-items: center;
                gap: 7px;
              }
    
              .tooltip-container {
                z-index: 3;
                
                .tooltip-content {
                  top: -70px;
                  font-size: 14px;
                  left: -330px;
                  max-width: 320px;
                }
              }
            }
          }
        }
      }
  
      .table-body {
        tr {
          td {
            .two-cell {
              width: 250px;
            }
            .button-cell {
              width: 150px;
            }
          }
        }
      }
    }
  }

  .pagination-bar-container {
    width: calc(100vw - 300px);
    display: flex;
    justify-content: center;

    .pagination-bar {
      position: fixed;
      margin-top: 30px;
    }
  }
}