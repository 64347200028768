.button.outline-button {
  background: #FFFFFF;
  color: #0F172A;
  border: 1px solid #E2E8F0;

  &:hover {
    background: #EAE8FA;

    &:disabled, &[disabled=true] {
      background: #FFFFFF;
    }
  }
}