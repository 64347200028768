.nav-item-content {
  width: calc(100% - 48px - 15px * 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
  background: #FFFFFF;
  color: #334155;
  padding: 8px 15px;
  border-radius: 6px;
  margin: 0 24px;

  &.selected {
    background: #231F20;
    color: #FFFFFF;

    .label {
      color: #FFFFFF;
      font: 500 15px Inter, 'Arial', sans-serif;
    }

    .count {
      color: #FFFFFF;
    }
  }

  &.bottom-divider {
    border-bottom: #E2E8F0 1px solid;
  }

  .label {
    display: flex;
    align-items: center;
    font: 400 15px Inter, 'Arial', sans-serif;
    color: #334155;

    .icon {
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }
  }

  .count {
    color: #64748B;
  }
}