.verification-code-container {
  display: inline-block;
  position: relative;
  display: flex;
  margin: 1rem 0 0.5rem;

  .code-input {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
    margin: 0 1rem;
    padding: 0;
    font-family: Inter, sans-serif, Arial;

    @media (max-width: 480px) {
      margin: 0 0.5rem;
    }
  }
}