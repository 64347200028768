.button.black-button {
  background: #000000;
  color: #FFFFFF;
  border: 1px solid #000000;
  color: #FFFFFF;

  &:hover {
    background: #1d1d1d;
    border: 1px solid #1d1d1d;
    cursor: pointer;
  }
}