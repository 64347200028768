.case-study-cards-container {
  .case-study-cards-header {
    .title { 
      margin: 0 0 15px 0;
      font: 600 28px Inter, 'Arial', sans-serif;
    }
  
    .subtitle {
      font: 400 18px Inter, 'Arial', sans-serif;
      margin: 0 0 30px 0;
    }
  }

  .cards {
    display: flex;
    flex-flow: row wrap;
    gap: 30px;
  }
}