.time-period-picker .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  text-align: center;
  height: 38px;
}

.time-period-picker {

  .react-datepicker-wrapper {
    width: 100%;
    
    .react-datepicker__input-container {
      width: 100%;

      & input {
        width: 100%;
        min-width: 200px;
        border-radius: 6px;
        border: 1px solid #E2E8F0;
        font: 500 15px Krub, sans-serif, Arial;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 2;

    .react-datepicker {
      box-shadow: 0px 0px 30.5201px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 15px;
      border: none;
  
  
      .react-datepicker__navigation--previous {
        right: 40px;
        top: 25px;
        left: auto;
      }
      
      .react-datepicker__navigation--next {
        right: 7px;
        top: 25px;
      }
  
      .react-datepicker__navigation-icon:before {
        border-color: #121212;
      }
  
      .react-datepicker__month-container {
  
        .react-datepicker__header {
          background-color: #FFFFFF;
          border-bottom: none;
  
          .react-datepicker__current-month {
            color: #121212;
            display: flex;
            padding: 10px;
            font: 900 15px Krub, sans-serif, Arial;
          }
        }
  
        .react-datepicker__day--outside-month {
          visibility: hidden;
        }
  
        .react-datepicker__day-name,  .react-datepicker__day, .react-datepicker__time-name {
          font-family: Krub, sans-serif, Arial;
        }
  
        .react-datepicker__day--selected {
          background: #5200FF;
          color: #FFFFFF;
        }
  
        .react-datepicker__day--keyboard-selected {
          background-color: transparent;
          color: #000000;
        }
  
        .react-datepicker__day--highlighted.react-datepicker__day--keyboard-selected,  .react-datepicker__day--highlighted {
          background: #5200FF;
          color: #FFFFFF;
        }
      }
    }
  }
}