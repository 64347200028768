.account-activity-log {
  .activity-log-header {
    .activity-log-title {
      margin: 30px 0 10px 0;
      font: 600 18px Inter, Arial sans-serif;
    }
  }

  .activity-log-list {
    margin-top: 15px;
    display: flex;
    flex-flow: column;
    gap: 12px;

    .empty-activity-log {
      font: 400 16px Inter, Arial sans-serif;
      margin: 10px 0;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}