.stripe-pricing-table-card {
  display: flex;
  flex-flow: column;
  padding: 25px 29px;
  border: 1px solid #f3edff;
  background-color: #f3edff;
  border-radius: 0;
  width: 240px;

  &.highlighted {
    background-color: #e7e2f2;
    border: 1px solid #d3cedc;
    border-radius: 6px;
  }

  .stripe-pricing-table-content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .card-header {
      display: flex;
      flex-flow: column;
      height: 240px;

      .card-tag-container {
        height: 30px;
        position: relative;
        top: -10px;

        .card-tag {
          font: 500 12px Inter, Arial sans-serif;
          color: #545a69;
          background: #FFFFFF;
          padding: 4px 6px;
          text-align: center;
          border-radius: 6px;
        }
      }

      .card-title {
        font: 600 20px Inter, Arial sans-serif;
        margin: 0;
        color: #1a1a1a;
      }

      .card-subtitle {
        font: 400 14px Inter, Arial sans-serif;
        color: #1a1a1a;
        opacity: 0.5;
        margin: 10px 0;
      }
    }

    .card-footer {
      height: 95px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .pricing-content {
        display: flex;
        align-items: center;
        gap: 5px;

        .price {
          font: 700 36px Inter, Arial sans-serif;
          color: #1a1a1a;
          margin: 0;
        }

        .price-description {
          width: 50px;
          font: 400 13px Inter, Arial sans-serif;
          color: #1a1a1a;
          opacity: 0.5;
        }
      }
    }
  }
}