.add-filter-modal {
  .modal-content {
    min-width: 800px;
  }

  .add-filter-modal-header {
    .add-filter-title {
      font: 600 20px/24px Inter, Arial, sans-serif;
      color: #0F172A;
      margin: 0 0 10px 0;
    }
  
    .add-filter-subtitle {
      font: 400 16px/18px Inter, Arial, sans-serif;
      color: #64748B;
      margin: 0 0 20px 0;
      line-height: 24px;
    }
  }

  .add-filter-content {
    .filters {
      display: flex;
      flex-flow: column;
      gap: 10px;
      margin-bottom: 15px;
    }
  }

  .add-filter-buttons {
    align-self: flex-end;
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }
}