.preview-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px 10px 20px;
  gap: 10px;

  .button.outline-button {
    width: 100px;
    padding: 8px 10px;
  }

  .button.subtle-button, .button.primary-button {
    width: 70px;
    padding: 8px 10px;
  }
}