.tooltip-container.information-icon-tooltip {
  .tooltip-item {
    display: flex;
    justify-content: center;
  }

  .information-icon {
    width: 15px;
    height: 15px;
  }

  .tooltip {
    .tooltip-content {
      right: 0px;
    }
  }
}