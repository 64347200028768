.dashboard-metric-list-container {
  display: flex;
  flex-flow: column;
  gap: 12px;

  .metric-title {
    font: 600 16px Inter, 'Arial', sans-serif;
  }

  .dashboard-metric-list {
    display: flex;
    flex-flow: wrap;
    gap: 20px;
  
    // Make first element information tooltip start left to right
    .dashboard-metric-item:first-child, .dashboard-metric-item:nth-child(2) {
       .metric .metric-count .metric-label .tooltip-container .tooltip {
        .tooltip-content {
          left: 0px;
        }
      }
    }

    @media (max-width: 1327px) {
      // Wrap behavior makes it so the 4th metric is positioned horizontally at the same point as the first child
      .dashboard-metric-item:first-child, .dashboard-metric-item:nth-child(2), .dashboard-metric-item:nth-child(4) {
        .metric .metric-count .metric-label .tooltip-container .tooltip {
         .tooltip-content {
           left: 0px;
         }
       }
     }
    }

    @media (max-width: 1062px) {
      // Wrap behavior makes it so the 4th metric is positioned horizontally at the same point as the first child
      .dashboard-metric-item:first-child, .dashboard-metric-item:nth-child(2), .dashboard-metric-item:nth-child(3), .dashboard-metric-item:nth-child(4) {
        .metric .metric-count .metric-label .tooltip-container .tooltip {
          .tooltip-content {
            left: 0px;
          }
        }
      }
    }
  }
}