.verify-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .resend-button {
    font-size: 12px;
    margin: 0.5rem 1rem;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-family: Inter, sans-serif, Arial;

    @media (max-width: 480px) {
      margin: 0.5rem;
    }
  }

  .new-verification-method {
    font-size: 12px;
    margin: 0.5rem 1rem;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-family: Inter, sans-serif, Arial;

    @media (max-width: 480px) {
      margin: 0.5rem;
    }
  }

  .sms-validation-error {
    color: #FF0000;
    align-self: center;
  }
}