.commercial-outreach-table-container {
  .search-bar-container {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }

  .pagination-bar-container {
    width: calc(100vw - 340px);
    display: flex;
    justify-content: center;

    .pagination-bar {
      margin-top: 0;
    }
  }

  .table-container {
    overflow: auto;
    max-width: calc(100vw - 300px - 20px);
    padding-bottom: 20px;

    .table .table-body tr td .two-cell {
      width: 140px;
      padding-left: 10px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.linked-in-url-cell {
        display: flex;
        align-items: center;
        gap: 15px;
        text-align: center;
        width: 240px;

        .linked-in-url-tag {
          text-decoration: none;
          color: #000000;
          font: 500 14px Inter, 'Arial', sans-serif;
        }
      }
    }

    .table .table-body tr td .three-cell {
      width: 200px;
      padding-left: 10px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .table .table-body tr td .button-cell {
      .outline-button {
        &:disabled {
          background: #7A7A7A;
          border: 1px solid #7A7A7A;
          color: #FFFFFF;
      
          &:hover {
            background: #7A7A7A;
            border: 1px solid #7A7A7A;
            cursor: auto;
          }
        }
      }
    }

    .table .table-body tr td .four-cell {
      width: 240px;
      padding-left: 10px;

      .link-cell {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;

        .name-icon {
          background: #5200FF;
          color: #FFFFFF;
          border-radius: 100%;
          height: 20px;
          width: 20px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .table .table-body tr td .proximity-cell {
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    .table .table-body tr td .button-cell {
      width: 135px;
      font-size: 14px;
    }

    .table .table-body tr td .tag-cell {
      width: 110px;
      font-size: 14px;
    }

    .table .table-body tr td .icon-button-cell {
      width: 80px;
      display: flex;
      justify-content: center;
    }
  }
}


