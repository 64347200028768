.message-preview-page {
  margin: 20px 30px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  max-width: 1200px;
  width: calc(100vw - 360px);

  .message-preview-page-header {
    width: 100%;

    .title { 
      margin: 0 0 15px 0;
      font: 600 26px Inter, 'Arial', sans-serif;
    }

    .subtitle {
      margin: 0 0 20px 0;
      font: 400 18px Inter, 'Arial', sans-serif;
    }
  }

  .message-preview-page-content {
    display: flex;
    flex-flow: column;
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px;

    .footer-left {
      display: flex;
      align-items: center;
      gap: 20px;

      .subtle-button.button {
        min-width: 195px;

        &.review {
          min-width: 220px;
        }
      }

      .primary-button {
        min-width: 190px;
      }
    }

    .footer-right {
      width: 100%;
      text-align: center;
    }
  }
}