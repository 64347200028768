.account-activity-log-item {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    justify-content: center;
    width: 20px;
    margin-right: 10px;
  }

  .activity {
    color: #334155;
    width: 150px;
    font: 500 14px Inter, Arial, sans-serif;
  }

  .description {
    width: 420px;
    color: rgba(0, 0, 0, 0.4);
    font: 400 14px Inter, Arial, sans-serif;
  }

  .date, .time {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font: 400 14px Inter, Arial, sans-serif;
  }

  .date {
    width: 100px;
  }

  .time {
    width: 80px;
  }

  .credits {
    border: 1px solid #E2E8F0;
    margin-left: 25px;
    border-radius: 6px;
    color: #231F20;
    text-align: center;
    width: 80px;
    padding: 3px 0;
    font: 500 14px Inter, 'Arial', sans-serif;
  }
}
