.button.primary-blue-button {
  background: rgb(52, 0, 255);;
  color: #FFFFFF;
  border: 1px solid rgb(52, 0, 255);;

  &:disabled {
    background: #7A7A7A;
    border: 1px solid #7A7A7A;

    &:hover {
      background: #7A7A7A;
      border: 1px solid #7A7A7A;
      cursor: auto;
    }
  }

  &:hover {
    background: rgb(52, 0, 255);;
    border: 1px solid rgb(8, 7, 15);;
  }

  &.loading {
    &:hover {
      background: rgb(52, 0, 255);;
      border: 1px solid rgb(52, 0, 255);;
    }
  }
}