.handwritten-letters-table-container {
  .pagination-bar-container {
    width: calc(100vw - 560px);
    display: flex;
    justify-content: center;
  }

  .table-container {
    overflow: auto;
    padding-bottom: 20px;
    overflow: auto;
    max-width: calc(100vw - 300px - 20px);
    padding-bottom: 20px;

    .table .table-body tr td .one-cell.long-header {
      width: 160px;
    }

    .table .table-body tr td .button-cell.two-button-cell {
      width: 140px;
    }

    .table .table-body tr td .proximity-cell {
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    .table .table-body tr td .one-button-cell {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .button-cell {
        padding: 8px 10px;
        font-size: 14px;
        width: 114px;
      }
    }

    .table .table-body tr td .tag-cell {
      font-size: 14px;
      padding: 8px 10px;
    }
  }
}