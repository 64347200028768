.follow-up-message {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 15px;

  .delay-days-selector {
    font-weight: 400;
    background: #EAE8FA;
    width: 180px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 3px;
    margin-bottom: 15px;
    font: 400 16px Inter, 'Arial', sans-serif;

    .num-days {
      width: 125px;
      text-align: center;
    }

    .button.ghost-button {
      background: #FFFFFF;
      font-weight: 400;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10.06px;
      border-radius: 3px;
    }
  }

  .message {
    width: 100%;
    padding: 20px;
    border: 1px solid #E4E4E6;
    width: calc(100vw - 440px);
    border-radius: 3px;

    .message-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .message-title {
        color: rgba(0, 0, 0, 0.6);
        font: 600 15px Inter, Arial, sans-serif;
        padding-bottom: 15px;
        margin: 0 0 10px 0;
        border-bottom: 1px solid #E4E4E6;
        width: 250px;
      }

      .button.ghost-button {
        padding: 0;
        position: relative;
        top: -12px;
      }
    }

    .message-text {
      padding: 5px;
      width: calc(100% - 10px);
      border: none;
      min-height: 120px;
      font: 400 16px Inter, Arial, sans-serif;
      outline: none;
      resize: none;

      &::placeholder {
        color: #94A2B8;
      }
    }
  }
}



