/* The switch - the box around the slider */
.generic-switch {
  display: flex;
  align-items: center;
  gap: 10px;

  .label-text {
    font-weight: 500;
    margin-left: 2px;
    font: 400 16px Inter, 'Arial', sans-serif;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #0E1729;

        &:before {
          -webkit-transform: translateX(20px);
          -ms-transform: translateX(20px);
          transform: translateX(20px);
        }
      }

      &:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #E2E7F1;
      -webkit-transition: .4s;
      transition: .4s;

      &.round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }
  }
}