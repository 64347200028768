.fine-tune-modal-content {
  display: flex;
  flex-direction: column;
  width: 500px;
  
  .title { 
    margin: 0 0 15px 0;
    font: 600 24px Inter, 'Arial', sans-serif;
  }

  .subtitle {
    font: 400 16px Inter, 'Arial', sans-serif;
    margin: 0 0 20px 0;
  }

  .disable-ai {
    display: flex;
    column-gap: 15px;
    margin-bottom: 15px;

    .icon-button {
      border: none;
      padding: 0;
    }

    .clickable {
      cursor: pointer;
    }

    .disable-ai-text {
      font: 400 14px Inter, 'Arial', sans-serif;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    column-gap: 2rem;
  }
}