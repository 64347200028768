.email-forwarding {
  margin-bottom: 10px;

  .settings-header {
    display: flex;
    flex-flow: column;
    gap: 15px;

    .settings-title {
      margin: 0;
      font: 600 18px Inter, 'Arial', sans-serif;
    }

    .settings-subtitle {
      font: 400 16px Inter, 'Arial', sans-serif;
      color: #64748B;
      margin: 0 0 10px 0;
    }
  }

  .preferences {
    display: flex;
    flex-flow: column;
    gap: 30px;

    .preference {
      display: flex;
      flex-flow: column;
      gap: 12px;
  
      .preference-title {
        font: 600 16px Inter, 'Arial', sans-serif;
      }
  
      .preference-subtitle {
        color: #64748B;
        font: 400 16px Inter, 'Arial', sans-serif;
        margin: 0 0 10px 0;
      }

      .email-to-sms-dropdown {
        z-index: 3;
      }

      .phone-number-dropdown {
        z-index: 2;
      }
      
      .email {
        display: flex;
        gap: 20px;
        align-items: center;
  
        .label {
          width: 200px;
          font: 400 16px Inter, 'Arial', sans-serif;
        }
  
        .primary-input {
          width: 400px;
        }
      }
    }
  }
}