.email-list {
  display: flex;
  flex-flow: column;

  .email-list-item {
    .to {
      width: 80px;
      color: rgba(15, 23, 42, 0.5);
      font: 600 15px Inter, 'Arial', sans-serif;
    }

    .email {
      width: 420px;
      color: rgba(15, 23, 42, 0.5);
      font: 600 15px Inter, 'Arial', sans-serif;
    }
  }
}