.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  white-space: normal;
  background-color: rgba(35, 31, 32, 0.45);

  .modal-content {
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 6px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: max-content;
    border: 1px solid #CBD5E1;
  }

  &.flatfile-upload-modal {
    .modal-content {
      padding: 0;
    }
  }
}
