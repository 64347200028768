.sms-preview {
  font: 500 14px Inter, Arial, sans-serif;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  height: 236px;
  width: 100%;
  overflow: auto;
  outline: none;
  @media (max-height: 720px) {
    height: 200px;
  }
  @media (max-height: 700px) {
    height: 180px;
  }
  @media (max-height: 680px) {
    height: 160px;
  }
  @media (max-height: 660px) {
    height: 140px;
  }
  @media (max-height: 640px) {
    height: 120px;
  }
  @media (max-height: 620px) {
    height: 100px;
  }
}