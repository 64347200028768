.login-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  border: 1px solid #c2c8d0;
  border-radius: 3px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  text-align: left;
  margin: 1rem 0;
  cursor: pointer;
  background-color: #FFFFFF;
  font-family: Inter, sans-serif, Arial;

  &:hover {
    cursor: cursor;
    filter: brightness(90%);
  }
}