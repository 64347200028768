.plan-details {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 4px 4px rgba(174, 174, 174, 0.25);
  padding: 25px 15px;
  border-radius: 6px;
  width: 500px;
  height: 121.5px;

  .plan-details-title {
    margin: 0;
    font: 600 15px Inter, Arial sans-serif;
  }

  .plan-details-summary {
    margin: 20px 0;
    font: 400 15px Inter, Arial sans-serif;
    
    b {
      font-weight: 600;
    }
  }
  
  .plan-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    font: 500 15px Inter, Arial sans-serif;
  }
}