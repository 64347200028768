.account-details-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .account-details-header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 28px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 18px Inter, 'Arial', sans-serif;
        margin: 0 0 20px 0;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .account-details {
      display: flex;
      flex-flow: column;
      gap: 15px;
      margin-bottom: 30px;

      .account-details-section {
        display: flex;
        align-items: center;

        .section-label {
          width: 140px;
          margin-right: 60px;
          font: 500 15px Inter, 'Arial', sans-serif;
        }

        .primary-input {
          width: 320px;
          font-weight: 400;
        }
      }
    }
  }
}

