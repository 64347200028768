.dashboard-revenue-edit-modal-content {
  display: flex;
  flex-direction: column;
  width: 500px;
  
  .title { 
    margin: 0 0 15px 0;
    font: 600 24px Inter, 'Arial', sans-serif;
  }

  .subtitle {
    font: 400 16px Inter, 'Arial', sans-serif;
    margin: 0 0 20px 0;
  }

  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    
    .input-label {
      width: 200px;
      font: 500 16px Inter, 'Arial', sans-serif;
    }

    .primary-input-placeholder-container {
      position: relative;
      
      .primary-input {
        padding: 10px 8px;
      }

      .placeholder-dollar {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translate(-50%, -50%);
        font: 500 16px Inter, 'Arial', sans-serif;
        color: rgba(0,0,0,0.5);
      }

      .placeholder-percent {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(-50%, -50%);
        font: 500 16px Inter, 'Arial', sans-serif;
        color: rgba(0,0,0,0.5);
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    column-gap: 2rem;
  }
}