.usage-and-billing-page {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 20px 30px;

  .page {
    display: flex;
    flex-flow: column;

    .usage-and-billing-header {
      .title { 
        margin: 0 0 15px 0;
        font: 600 28px Inter, 'Arial', sans-serif;
      }
    
      .subtitle {
        font: 400 18px Inter, 'Arial', sans-serif;
        margin: 0 0 30px 0;
      }
    }

    .billing-details {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 40px;
    }

    .loading-page-content.loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
    }

    .account-activity-log {
      margin-top: 20px;
    }
  }
}

