.multi-select-row {
  .reactivate-clients-attributes-dropdown, .reactivate-clients-conditions-dropdown {
    position: relative;
    z-index: 2;
  
    &.dropdown-open {
      z-index: 3;
    }
  
    .dropdown-button {
      z-index: 1;
    }
  
    .dropdown-options-container {
      z-index: 3;
    }
  }
}

.multi-select-row .primary-input {
  min-width: 260px;
}