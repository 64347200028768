.dashboard-metric-item {
  border: solid 1px #E5E7EB;
  display: flex;
  flex-flow: column;
  box-shadow: 0px 4px 4px rgba(174, 174, 174, 0.25);
  border-radius: 6px;
  background: #FFFFFF;
  padding: 10px 15px 10px 15px;
  width: 210px;

  .metric {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    border-bottom: solid 1px #E2E8F0;
    padding: 15px 0;

    .metric-icon {
      margin-left: 10px;

      .icon {
        width: 30px;
        height: 30px;
      }
    }

    .metric-count {
      display: flex;
      flex-flow: column;
      gap: 6px;

      .metric-label {
        display: flex;
        align-items: center;
        gap: 6px;

        .metric-text {
          font: 600 15px Inter, 'Arial', sans-serif;
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .metric-count {
        align-self: flex-end;
        font: 600 32px Inter, 'Arial', sans-serif;
        color: #0F172A;
        letter-spacing: -0.012em;
      }
    }
  }

  .footer {
    display: flex;
    margin-top: 10px;
    gap: 4px;
    margin-left: 10px;
    align-items: center;
    min-height: 22px;
    justify-content: flex-end;

    &.space-between-icons {
      justify-content: space-between;
    }

    .last-update-container {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-end;

      .update-icon {
        width: 9px;
        height: 9px;
      }
  
      .last-update-text {
        color: rgba(0, 0, 0, 0.5);
        font: 400 10px Inter, 'Arial', sans-serif;
      }
    }

    .dashboard-settings-icon {
      border: none;
      padding: 3px;
    }
  }
}




