.preview-header {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E4E4E6;
  
  .preview-header-label {
    font: 600 15px Inter, Arial, sans-serif;
  }

  .preview-name, .preview-target, .preview-schedule, .preview-number {
    color: #94A3B8;
    font: 500 15px Inter, Arial, sans-serif;
  }

  .labels {
    display: flex;
    align-items: center;
    gap: 10px;

    .preview-header-label {
      width: 80px;
    }

    .preview-name {
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .preview-target {
      width: 160px;
    }
  }
}