.navigation-sidebar {
  width: 280px;
  min-width: 280px;
  height: calc(100vh - 40px);
  position: fixed;

  .sidebar-body {
    width: 100%;

    .sidebar-header {
      padding: 10px 24px;
      display: flex;
      justify-content: center;
      border-bottom: #E2E8F0 1px solid;
  
      .dropdown {
        width: 100%;
  
        .dropdown-button {
          border-color: #CBD5E1;
        }
      }
    }

    .category-header {
      display: flex;
      margin-left: 35px;
      margin-bottom: 7px;
      padding-left: 5px;
      padding-bottom: 8px;
      margin-top: 15px;
      width: 140px;
      color: #334155;
      border-bottom: solid 1px #E2E8F0;
      font: 600 15px Inter, Arial, sans-serif;
    }

    .sidebar-header + .nav-container,
    .nav-container.section-end + .nav-container {
      padding-top: 10px;
    }

    .nav-container {
      padding-top: 8px;
    }

    .accordion {
      width: 100%;

      &.nav-item-content-container {
        width: 100%;
        
        &.bottom-divider {
          border-bottom: #E2E8F0 1px solid;
          padding-bottom: 10px;
        }
      }
      
      .accordion-button {
        width: 100%;
        background: transparent;
        padding: 0;
        border-radius: 6px;
        
        .button-content {
          justify-content: space-between;
        }
      }

      &.empty.open {
        .accordion-button .button-content {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .logout-button-container {
    align-self: center;
    margin-bottom: 20px;
  }
}
