.stripe-pricing-table {
  display: flex;
  align-items: center;
  gap: 0;
  padding: 1px 5px;
  background-color: #f3edff;
  border-radius: 30px;

  .stripe-pricing-table-card {
    &:first-child {
      border-radius: 30px 0 0 30px;
    }

    &:last-child {
      border-radius: 0 30px 30px 0;
    }
  }
}